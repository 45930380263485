.centered-card {
  left: 50%;
  max-width: 500px;
  min-width: 400px !important;
  position: absolute !important;
  top: 50%;
  transform: translate(-50%, -50%);
}

.card {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.no-scroll {
  overflow: hidden !important;
}

.fa {
  &-prepend {
    margin-right: 0.5rem;
  }

  &-append {
    margin-left: 0.5rem;
  }

  &-hover {
    cursor: pointer;
  }
}
