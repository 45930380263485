.loading {
  background-clip: padding-box;
  background-color: transparent;
  bottom: -1px;
  display: block;
  height: 2px;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;

  &-bar {
    background-color: $ns-blue;

    &::before {
      animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
      background-color: inherit;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      will-change: left, right;
    }

    &::after {
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 1.15s;
      background-color: inherit;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      will-change: left, right;
    }
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}
