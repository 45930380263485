.app-nav {
  display: flex;
  flex-direction: column;

  .logo {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: center;
  }

  .nav-item {
    cursor: pointer;

    a {
      color: #ffffff80;

      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }

    &.active {
      background-color: #ffffff11;
    }
  }
}
