.event-calendar {
  padding: 1rem;


  .event {
    margin: 1rem 0;
    
    &-timebar {
      @include drop-shadow;
      color: #fff;
      padding: .25rem 1rem;
      border-radius: $border-radius-base;

      &.mwr {
        background: $primary;
      }
    }
  }
}
