.loading-icon {
  &:not(.inline) {
    align-items: center;
    background-color: #ffffffcc;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 9999999;
  }

  &.inline {
    width: 1.5rem;

    .crescent {
      margin-top: .5rem;
    }
  }

  &.sm {
    .crescent {
      border: .15rem solid;
      height: 1.1rem;
      width: 1.1rem;
    }
  }

  &.md {
    .crescent {
      border: .5rem solid;
      height: 5rem;
      width: 5rem;
    }
  }

  &.lg {
    .crescent {
      border: .65rem solid;
      height: 10rem;
      width: 10rem;
    }
  }

  .crescent {
    border-radius: 50%;
    position: absolute;

    &.red {
      border-color: $ns-red;
    }

    &.blue {
      animation: rotate infinite 1s linear;
      border-bottom: 0;
      border-color: $ns-blue;
      border-left: 0;
      transform: scale(.8);
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(360deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(0deg);
  }
}
