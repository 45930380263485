@import './colors';
@import './mixins';
@import './utilities';

// Theming
$body-bg: $primary;
$text-color: $primary;

$font-family-sans-serif: Roboto, Helvetica, Arial, sans-serif;

// Options
$enable-shadows: false;
$enable-rounded: false;

$border-radius-base: 0.25rem !default;
$border-radius-large: 0.5rem !default;
$border-radius-small: 0.15rem !default;

// Libraries
@import '~bootstrap/scss/bootstrap';
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import '~react-bootstrap-typeahead/css/Typeahead-bs4.css';
@import '~react-widgets/dist/css/react-widgets.css';
@import '~react-quill/dist/quill.snow.css';
@import '~react-toastify/dist/ReactToastify.css';

// Components
@import './main';
@import 'src/components/Calendar/Calendar';
@import 'src/components/Navigation/Navigation';
@import 'src/components/Utilities/UserSearch';
@import 'src/components/Utilities/LoadingIcon';
@import 'src/components/Utilities/LoadingBar';

// Overrides
@import './overrides';
