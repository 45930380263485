// bootstrap
.input-group-append {
  margin-right: -1px;
}

.btn-outline-warning {
  color: black;
}

.nav-pills {
  li {
    a:hover {
      background-color: rgba($primary, .5) !important;
    }
  }
  .nav-item {
    cursor: pointer;
  }
}

// react-widgets
.rw-widget-container {
  border-radius: 0;
}

.rw-dropdown-list {
  flex: 1;
}

.rw-state-focus {
  > .rw-widget-container {
    border-color: #3e6ca5 !important;
    box-shadow: 0 0 0 0.2rem rgba(27, 47, 72, 0.25);
  }
}

// Toastify
.Toastify {
  a {
    color: #fff;
    font-weight: bold;
  }
}

.Toastify__toast--success {
  background-color: #28a745;
  border-color: #28a745;
}
