html {
  height: 100%;

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: rgba(255, 255, 255, .5);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #aaa;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  background: linear-gradient(0deg, lighten($primary, 20%) 0%, $primary 100%);
  color: $primary;
  height: 100%;
  margin: 0;
  min-height: 100%;
  overflow-y: hidden;
  padding: 0;
  position: absolute;
  text-rendering: geometricPrecision;
  width: 100%;

  .app {
    display: flex;
    flex-direction: row;
    height: 100vh;

    &-nav {
      flex-basis: 250px;
    }

    &-container {
      background-color: linear-gradient(0deg, lighten($primary, 20%) 0%, $primary 100%);
      flex: 10;
      max-width: unset;
      padding: 0;

      .component {
        &-container {
          background-color: #f8fafc;
          height: 100%;
          overflow-y: auto;
        }

        &-header {
          background-color: #fff;
          font-size: 1.5rem;
          margin: 1rem;
          padding: .5rem 1rem;
          position: sticky;
          top: 0;
          z-index: 9;
        }

        &-body {
          margin: 1rem;

          .list-group-container {
            .list-group-header {
              background-color: $primary;
              color: #fff;
              font-weight: bold;
            }

            // li:nth-child(2n){
            //   background:#d4d4d4;
            // }
          }

          .list-group-col {
            flex: 1;
            text-align: center;
          }
        }
      }
    }
  }
}
